body {
  background-color: rgb(21, 21, 21)!important;
  color: rgb(255, 255, 255)!important;

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Bison";
  src: local("Bison"),
   url("./fonts/bison.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Aquamax";
  src: local("Aquamax"),
   url("./fonts/aquamax.ttf") format("truetype");
  font-weight: bold;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

::selection {
  color: #3FA4B6;
}
::-webkit-scrollbar{
  width: 8px;
}
::-webkit-scrollbar-track{
  background: #222;
}
::-webkit-scrollbar-thumb{
  background: #efd162;
}

.font-bs {
  font-family: "Bison";
}
.font-aquamax {
  font-family: "Aquamax";
}
.font-rbt {
  font-family: 'Roboto Condensed', sans-serif;
}
.font-fira{
  font-family: 'Fira Sans'
}

.pix-form{
  color:#f1f1f1;
  border-radius:20px;
  margin-top: 30px;
  min-height: 1480px;
}
.pix-title{
  text-align: center;
  margin-top:60px;
}

.pix-box-title{
  color:#fff;
  margin-bottom: 5px;
  padding-top:10px;
  text-align: center;
}

.radio-in{
  width:100%;
}
.radio-in span{
  display:flex;
}
.radio-in label{
  width:90%;
  margin-bottom: 20px;
}

.radio-in input {
  width: 10%;
  height: 13px;
  margin-top: 10px;
  margin-bottom: 16px;
}


.pix-box-title-address{
  background-color: #efd162;
  color:#222;
  padding:12px;
  text-align: center;
  border-radius: 5px 5px 0px 0px;
  display: flex;
  justify-content: space-between;
}

.pix-input-client{
  padding: 13px;
  margin-bottom:10px;
  border: 1px;
  outline: 1px solid #fff;
  background-color: rgb(246, 246, 246);
}
.pix-input-client:focus{
  padding: 13px;
  margin-bottom:10px;
  border: 1px!important;
  outline: 1px solid #efd162;
  background-color: rgb(246, 246, 246);
}

.item-carrinho{
  background-color: rgb(255, 255, 255);
  color:#444;
  margin-bottom: 10px;
  border-radius: 0px 0px 5px 5px;
}

.total-carrinho{
  background-color:#efd162;
  color:#222;
  margin-bottom: 10px;
  margin-top: 40px;
  border-radius: 5px 5px 0px 0px;
  display: flex;
  justify-content: space-between;
}

.resumo{
  min-height:300px;
  background-color: rgb(255, 255, 255);
  color:#222;
  border-radius:5px;
  margin-bottom: 10px;
}

.center{
  text-align: center;
  color:#222;
  padding-top: 13px;
  padding-bottom: 13px;
  margin:0px;
}

.qrcode-textarea{
  max-width: 100%;
  color:#FFF;
  overflow: hidden;
}
.qrcode-textarea::-webkit-scrollbar{
  width: 0px;
}

.qrcode-title{
  display: flex;
  justify-content: center;
  width: 100%;
}

.qrcode-spinner{
  display: flex;
  justify-content:center;
  align-items: center;
  width: 100%;
  height: 300px;
}

.qrcode-image{
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  width: 100%;
}
.qrcode-image img{
  border-radius:10px;
  text-align: center;
  max-width: 150px;
}






.wahtsapp-button{
  position:absolute;
  z-index: 12342;
  width: 60px;
  height: 60px;
  font-size: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius:50%;
  bottom:32px;
  right:100px;
  float: right;
  background-color: #efd162;
  color:#555;
  cursor: pointer;
}

button{
  background-color: #efd162;
}
button:hover{
  background-color: #38d1ec;
}

.laranja{
  color:#efd162
}
.verde{
  color:#efd162
}
.azulzinho{
  color:#3FA4B6
}
.azulzinho:hover{
  color:#38d1ec
}

.bolinha{
  display: flex;
  justify-content:center;
  font-size: 30px;
  color:#3FA4B6;
}

.not-found{
  display:flex;
  justify-content:center;
  align-items:center;
  text-align: center;
  vertical-align: middle;
  height: 500px;
}


/* ================= LOGIN ================= */

.formlogin{
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top:10%;
  text-align: center;
  border-radius: 15px;
  border: 1px solid #ccc;
  padding:100px;
  max-width:700px;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

input {
  width: 100%;
  margin-bottom: 15px;
  padding: 12px 16px;
  border-width: 3px;
  border: 3px solid #ddd;
  border-radius: 4px;
  font-size: 15px;
  color: #444;
  transition: border-color 0.2s;
}

input:focus {
  border: 3px solid #ddd;
}

.headertitle{
  display:flex;
  text-align:center;
  border-bottom:1px solid #ccc;
}
.title{
  margin-bottom:0px;
}
.subtitle{
  color:#3FA4B6;
}

/* ================= BTN ================= */

.btn{
  border-width: 0px!important;
}

.btn strong{
  margin-bottom:0px;
}

.btn-stnweb {
  margin-right: 10px;
  margin-bottom: 10px;
  background-color: #efd162!important;
  color:#222;
  border-width: 0px!important;
  border-color: #efd162;
}
.btn-stnweb:hover {
  background-color:#3FA4B6!important;
  border-color: #efd162;
  color:#fff;
}
.btn-stnweb:active {
  background-color:#3FA4B6!important;
  border-color: #efd162;
  color:#fff;
}

.btn-payment-ok:hover{
  background-color:#85BF01!important;
}

.btn-payment-crash{
  background-color:#d50e00!important;
}

.btn-payment-crash:hover{
  background-color:#efd162!important;
}




.meumodal .modal-content{
  background-color: #ebebeb;
}

.modal-body{
  background-image: url(https://res.cloudinary.com/dcqngalxa/image/upload/v1672775036/whatsapp-bg_wshwcp.png);
  background-color: rgb(230, 221, 212);
  min-height: 350px;
  max-height: 400px;
  overflow: hidden;
  overflow-y:scroll;
}

.modal-body::-webkit-scrollbar{
  width: 6px;
}
.modal-body::-webkit-scrollbar-track{
  background: rgb(230, 221, 212);
}
.modal-body::-webkit-scrollbar-thumb{
  background: rgba(156, 149, 142, 0.5);
}

.meumodal .modal-body .modal-conversation{
  color:#444;
  border-radius: 0px 10px 10px 10px;
  background-color: #008069;
  color:#fff;
  padding: 10px 10px 5px 10px;
  font-weight: 500;
  font-size: 14px;
}

.meumodal .modal-body .modal-conversation::before {
  content: "";
  width: 0; 
  height: 0; 
  border-top: 0px solid transparent;
  border-bottom: 15px solid transparent; 
  border-right: 5px solid #008069; 
  position: absolute;
  left: 11px;
  top:53px;
}

.meumodal .modal-body .modal-conversation::selection{
  color: #004b3e;
}

.meumodal .modal-body .modal-conversation-long{
  color:#444;
  border-radius: 10px 10px 10px 10px;
  background-color: #008069;
  color:#fff;
  padding: 10px 10px 5px 10px;
  font-weight: 500;
  font-size: 14px;
}


.input-send{
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
}

.meumodal .close{
  background-color: #d50e00!important;
}

.meumodal .button-send{
  background-color:#43A691;
  margin-left: 10px;
}

.meumodal .button-send:hover{
  background-color:#277866!important;
}

.meumodal .button-loading{
  background-color:#efd162;
  color:#43A691;
  font-weight: bold;
}
.meumodal .button-loading:hover{
  background-color:#fce48d!important;
  color: #348775!important;;
}

.meumodal .button-talk{
  background-color:#43A691;
  font-weight: bold;
}
.meumodal .button-talk:hover{
  background-color:#32f570!important;
}



/* ================= HEADER ================= */

.header{
  vertical-align: middle;
  padding-bottom:20px;
  margin-bottom:50px;
  top:0px;
  position: sticky;
}

.logotipo{
  cursor: pointer;
  margin-top: 20px;
  margin-left: 50px;
  width:150px;
}

.menu-header{
  padding-right: 50px;
}

.menu-stnweb {
  margin: 0 auto;
  text-align: center;
  margin-top:5px;
  display: flex;
  justify-content: flex-end;
  padding-top:50px;
}

.menu-stnweb p{
  float: left;
  padding: 0px 5px;
  margin: 0px 10px;
  font-size: 16px;
  cursor: pointer;
}
.menu-stnweb p:hover, i:hover{
  color:#3FA4B6;
}

.menu-stnweb img{
  padding-right: 5px;
  width:20px;
}

/* ================= VIDEO ================= */

video{
  position:absolute;
  width:100%;
  height:100%;
  min-height: 100vh; 
  top:0px;
  object-fit: cover;
  opacity: 0.3; 
  z-index: -1; 
}

.videohome{
  filter: blur(0px);
  -moz-transform:scale(1);
  -webkit-transform:scale(1);
  -o-transform:scale(1);
  -ms-transform:scale(1);
  transform:scale(1);
}

.overlay{ 
  position: relative; 
  z-index: 22; 
} 

.bg{
  opacity: 1; 
  background-image: url(./assets/bg.png); 
  background-size: cover;
  height: 100vh;
  overflow: hidden;
  overflow-y: scroll;
  width: 100vw;
}

.bgnot{
  opacity: 1; 
  height: 100vh;
  overflow: hidden;
  overflow-y: scroll;
  width: 100vw;
}

.actions_btn{
  display: flex;
  justify-items: flex-end;
  width:100%;
}


/* ================= BOX SLIDE PRINCIPAL ================= */

.box-title-stn{
  padding-top:50px;
}
.titlestnweb{
  font-size: 7rem;
  line-height:7rem;
  color:#fff;
  text-shadow: rgb(46, 46, 46) 4px 3px 2px;
  padding-top: 0px;
}
.titlestnweb::selection {
  user-select: none;
}

.trheebox{
  margin-top: 80px;
  margin-bottom: 80px;
}
.trheebox .mb{
  font-size:60px;
  margin-bottom:20px;
}

.tecnologias span{
  font-size:35px;
  padding:5px;
}
.tecnologias-dash span{
  font-size:32px;
  padding:5px;
}
.iphone_home img{
  max-width: 75%;
  margin-right: 100px;
  cursor: pointer;
}

/* ================= CLIENTES ================= */

.clientes{
  padding-top:0px;
  padding-bottom: 100px;
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:row;
  flex-wrap: wrap;
}
.clientes img{
  cursor: pointer;
  margin:29px;
}

.clientes .arrow-action{
  display:flex;
  justify-content: center;
}

/* ================= BOXES ================= */

.box-dashboard{
  padding-top:100px;
  padding-bottom: 100px;
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:row;
  flex-wrap: wrap;
}
.box-dashboard ul li{
  color:#fff
}
.img-dashboard{
  display: flex;
  justify-content:center;
}

.box-appiosandroid{
  padding-top:100px;
  padding-bottom:100px;
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:row;
  flex-wrap: wrap;
}
.img-appiosandroid{
  display: flex;
}
.img-appiosandroid img{
  width:100%;
}

.box-designgrafico{
  padding-top:100px;
  padding-bottom: 100px;
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:row;
  flex-wrap: wrap;
  /* border-top: 1px solid #efd162; */
}
.img-designgrafico img{
  width:80%;
}

.img-designdigital img{
  width:100%;
}

/* ================= FOOTER ================= */

.footer{
  margin-top: 50px;
  min-height: 250px;
}
.copyright{
  text-align: center;
  min-height:50px;
}

/* ================= PADRAO SEM MEDIA QUERIES ================= */

@media only screen and (min-width: 320px) and (max-width: 375px) {
  
  .logotipo{
    cursor: pointer;
    margin-top: 10px;
    margin-left: 10px;
    width:100px;
  }
  .titlestnweb {
    font-size: 3rem;
    line-height: 3rem;
  }
  .box-title-stn p{
    font-size: 13px;
  }
  .iphone_home {
    display:none;
  }
  .trheebox{
    margin-top: 40px;
  }
  .trheebox .mb{
    font-size:40px;
  }
  .one-box{
    margin-bottom: 50px;
  }
  .img-dashboard img{
    max-width:100%;
    margin-bottom:20px;
  }
  .img-appiosandroid{
    display:none;
  }
  .img-designgrafico{
    display:none;
  }
  .img-designgrafico-mobile img{
    max-width:100%;
    margin-bottom:20px;
  }
  .img-appiosandroid-mobile img{
    max-width:100%;
    margin-bottom:20px;
  }
  .mobile-nao-ta{
    display:none;
  }

  .clientes{
    padding-top:0px;
    padding-bottom:0px;
    display:flex;
    justify-content:space-around;
    align-items:center;
    flex-direction:row;
    flex-wrap: wrap;
  }
  .clientes img{
    cursor: pointer;
    margin:2px;
    max-width:100px;
  }
  .box-footer{
    margin-bottom:50px;
  }
  .copyright {
    font-size:12px
  }

}




@media only screen and (min-width: 376px) and (max-width: 414px) {
  
  .logotipo{
    cursor: pointer;
    margin-top: 20px;
    margin-left: 20px;
    width:100px;
  }
  .box-title-stn {
    padding-top: 40px;
  }
  .titlestnweb {
    font-size: 4rem;
    line-height: 4rem;
  }
  .box-title-stn p{
    font-size: 12px;
  }
  .iphone_home {
    display:none;
  }
  .trheebox{
    margin-top: 40px;
  }
  .trheebox .mb{
    font-size:40px;
  }
  .one-box{
    margin-bottom: 50px;
  }
  .img-dashboard img{
    max-width:100%;
    margin-bottom:20px;
  }
  .img-appiosandroid{
    display:none;
  }
  .img-designgrafico{
    display:none;
  }
  .img-designgrafico-mobile img{
    max-width:100%;
    margin-bottom:20px;
  }
  .img-appiosandroid-mobile img{
    max-width:100%;
    margin-bottom:20px;
  }
  .mobile-nao-ta{
    display:none;
  }

  .clientes{
    padding-top:0px;
    padding-bottom:0px;
    display:flex;
    justify-content:space-around;
    align-items:center;
    flex-direction:row;
    flex-wrap: wrap;
  }
  .clientes img{
    cursor: pointer;
    margin:7px;
    max-width:100px;
  }
  .box-footer{
    margin-bottom:50px;
  }
}




@media(min-width: 415px) {
  .img-designgrafico-mobile{
    display:none;
  }

  .img-appiosandroid-mobile{
    display:none;
  }
}


@media(max-width: 1144px) {
  .menu-stnweb{
    display: none
  }
}


@media(max-width: 1920px) {
  .header{
    margin-bottom: 0px;
    background-color: rgb(20, 20, 20, 0.9);
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  }
}